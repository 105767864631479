<div class="tw-p-7">
  <h2 class="tw-font-bold tw-mb-6">Update Master</h2>
  <button (click)="closeModal()" class="btn-close absolute"></button>
  <form [formGroup]="uploadMasterForm">
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
      <div>
        <label>Masters</label>
        <select formControlName="masterName" class="form-select sm">
          <option value="">- Select Masters -</option>
          @for (master of UpdatedMaster; track master) {
            <option [value]="master.masterId">{{ master.masterName }}</option>
          }
        </select>
      </div>
      <div>
        <label class="mb-2">Overwrite Existing Data? </label>
        <div class="flex-inline">
          <label class="radio">
            <input class="form-check-input" type="radio" formControlName="isOverrideFile" value="true" />
            Yes
          </label>
          <label class="radio">
            <input class="form-check-input" type="radio" formControlName="isOverrideFile" value="false" />
            No
          </label>
        </div>
      </div>
    </div>
    @if (lastUploadedFile) {
      <p class="tw-text-xs tw-mt-2 tw-text-gray-400">Last Uploaded File: {{ lastUploadedFile }}</p>
    }
    <div>
      <label class="form-label tw-mt-4">Select File (CSV)</label>
      <input
        class="form-control"
        type="file"
        formControlName="inputFile"
        accept=".csv"
        (change)="onFileChange($event)"
      />
    </div>

    @if (!isUploadComplete) {
      <button [disabled]="uploadMasterForm.invalid" (click)="uploadData()" class="btn btn-primary mt-4 !tw-px-5">
        Upload
      </button>
    } @else {
      <div class="status-info tw-mt-3">
        <div class="status">
          <div [class]="getStatusColorClass()" class="status-wrapper tw-flex tw-gap-1">
            @if (statusInfo.status === 'INPROGRESS') {
              <span class="tw-text-xs tw-font-semibold tw-italic">{{ getDisplayStatus(statusInfo.status) }}</span>
            } @else {
              <span class="tw-text-xs tw-font-bold">{{ getDisplayStatus(statusInfo.status) }}</span>
            }
          </div>

          @if (hasLastUpdated()) {
            <small class="updated-date">Processed at: {{ statusInfo.lastUpdated | date: 'dd/MM/yyyy, HH:mm' }}</small>
          }
        </div>
      </div>
      <div class="tw-flex tw-gap-2 tw-mt-3">
        @if (!isRefreshVisible && uploadMasterForm.get('masterName')?.value !== '') {
          <button (click)="onRefresh($event)" class="btn-tiny text-link">Refresh</button>
        }
        @if (isUploadComplete) {
          <button class="btn-tiny text-link" (click)="uploadAnother($event)">Upload Another</button>
        }
      </div>
    }
  </form>
</div>
