import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { WorkflowService } from '@app/services/workflow.service';
import { MasterDetails } from '@app/types/garnet.type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIconComponent } from '@ng-icons/core';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-master-upload',
  standalone: true,
  imports: [NgIconComponent, DatePipe, ReactiveFormsModule],
  templateUrl: './master-upload.component.html',
  styleUrl: './master-upload.component.css',
})
export class MasterUploadComponent implements OnInit {
  @Input() workflowId!: string;

  uploadMasterForm = new FormGroup({
    masterName: new FormControl('', [Validators.required]),
    isOverrideFile: new FormControl('false'),
    inputFile: new FormControl(null, [Validators.required]),
  });

  masterSelectControl = new FormControl('');

  UpdatedMaster: MasterDetails[] = [];
  file!: File | null;
  isUploadComplete = false;
  isRefreshVisible = false;
  lastUploadedFile: string | null = null;

  statusInfo = {
    status: 'INPROGRESS', // Default status
    lastUpdated: null as string | Date | null, // Initially no last updated date
  };

  constructor(
    private workflowSvc: WorkflowService,
    private toastr: ToastrService,
    private modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.workflowId = '35';
    this.statusInfo = {
      status: 'INPROGRESS',
      lastUpdated: null,
    };
    this.getUpdatedMaster();

    this.uploadMasterForm.get('masterName')?.valueChanges.subscribe((selectedMasterId) => {
      if (selectedMasterId) {
        this.getLastUploadedFile(Number(selectedMasterId));
        this.uploadMasterForm.get('inputFile')?.reset();
        this.uploadMasterForm.get('isOverrideFile')?.patchValue('false');
      }
    });
  }

  getLastUploadedFile(masterId: number) {
    const selectedMaster = this.UpdatedMaster.find(
      (master) => Number(master.masterId) === masterId,
    );
    if (selectedMaster) {
      this.lastUploadedFile = selectedMaster.fileName;
    } else {
      this.lastUploadedFile = null; // No file name available
    }

    console.log('Last Uploaded File', this.lastUploadedFile);
  }

  getUpdatedMaster() {
    this.workflowSvc.getMasterDetails().subscribe((resp) => {
      if (resp) {
        this.isRefreshVisible = true;
        this.UpdatedMaster = resp.response;
      } else {
        this.isRefreshVisible = false;
      }
    });
  }

  onFileChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files[0]) {
      this.file = inputElement.files[0];
    }
  }

  getStatusColorClass() {
    switch (this.statusInfo.status) {
      case 'COMPLETE':
        return 'text-success';
      case 'INPROGRESS':
        return 'text-warning';
      case 'FAILED':
        return 'text-danger';
      default:
        return ''; // Default if status is unknown
    }
  }

  getDisplayStatus(status: string): string {
    switch (status) {
      case 'INPROGRESS':
        return 'Processing your file';
      case 'COMPLETE':
        return 'Success';
      case 'FAILED':
        return 'Failed';
      default:
        return status; // return status as is if no match
    }
  }

  hasLastUpdated(): boolean {
    return this.statusInfo.lastUpdated !== null && this.statusInfo.lastUpdated !== undefined;
  }

  uploadData(): void {
    if (this.uploadMasterForm.valid && this.file) {
      const formData = new FormData();

      formData.append('workflowId', this.workflowId.toString());

      const selectedMaster = this.UpdatedMaster.find(
        (master) => master.masterId == this.uploadMasterForm.value.masterName,
      );
      if (selectedMaster) {
        formData.append('masterId', selectedMaster.masterId);
        formData.append('masterName', selectedMaster.masterName);
      }

      formData.append('inputFile', this.file);

      const isOverrideFile = this.uploadMasterForm?.get('isOverrideFile')?.value;
      if (isOverrideFile) {
        formData.append('isOverrideFile', isOverrideFile);
      }

      console.log('Form Data Submitted', formData);

      this.workflowSvc.UpdateMaster(formData).subscribe((resp) => {
        if (resp) {
          this.isUploadComplete = true;
          const message = resp.message;
          this.toastr.success(message);
          this.getMasterStatus();
          this.uploadMasterForm.disable();
        }
      });
    } else {
      console.error('No file selected');
    }
  }

  getMasterStatus() {
    const masterId = this.uploadMasterForm.value.masterName;
    if (masterId) {
      this.workflowSvc.getMasterStatus(masterId).subscribe((resp) => {
        if (resp) {
          this.statusInfo = {
            status: resp.response.status,
            lastUpdated: resp.response.updateDate,
          };
        }
      });
    }
  }

  onRefresh(event: Event) {
    event.preventDefault();
    this.getMasterStatus();
  }

  uploadAnother(event: Event) {
    event.preventDefault();
    this.uploadMasterForm.enable();
    this.uploadMasterForm.reset();
    this.getUpdatedMaster();
    this.lastUploadedFile = null;
    this.statusInfo = {
      status: 'INPROGRESS',
      lastUpdated: null,
    };

    this.isUploadComplete = false;
  }

  closeModal() {
    this.modal.dismissAll();
  }
}
